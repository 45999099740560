import makeToken from '../mixins/Helpers';

const agreementsModel = [
    {
        id: makeToken.methods.makeToken(),
        title: 'Zgoda na otrzymywanie informacji handlowych i promocyjnych',
        ipressoId: 1,
        required: true,
        value: false,
    },
    {
        id: makeToken.methods.makeToken(),
        title: 'Zgoda na kontakt z wykorzystaniem urządzeń końcowych (prawo telekomunikacyjne)',
        ipressoId: 2,
        required: true,
        value: false,
    },
    {
        id: makeToken.methods.makeToken(),
        title: 'Zgoda na przetwarzanie danych w celach marketingowych',
        ipressoId: 3,
        required: true,
        value: false,
    },
];

const agreement = function() {
    return {
        id: makeToken.methods.makeToken(),
        title: '',
        ipressoId: '',
        required: true,
        value: false,
    }
};

export {agreementsModel, agreement};