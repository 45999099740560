<template>
	<Nav></Nav>

	<div class="container-fluid px-5 my-5">
		<div class="row">
			<div class="col">
				Brak tokenu ...
			</div>
		</div>
	</div>
</template>

<script>
	import Nav from '../components/Nav';

    export default {
        name: 'Home',
		components: {Nav},
		data() {
            return {

			}
		},
    }
</script>