<template>
	<div class="row my-3">
		<div class="col-4">
			<h3>Pole tekstowe</h3>
			<p>Pole tekstowe - klasyczny jedno linijkowy input, należy podać etykietę która będzie wyświetlała się nad inputem</p>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Etykieta</label>
						<input v-model="field.title" type="text" class="form-control" required>
					</div>

					<div class="form-group">
						<label>Słowa kluczowe</label>
						<div class="input-group mb-1">
							<input type="text" class="form-control" v-model="key" @keyup="addKeyKeyboard">
							<div class="input-group-append">
								<button class="btn btn-outline-secondary" type="button" @click="addKey">Dodaj</button>
							</div>
						</div>
						<div class="input-group mb-1">
							<button v-for="(key, index) in field.keys" type="button" class="btn btn-sm btn-info mr-2 mb-2">
								{{ key }}
								<svg @click="removeKey(index)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
									<path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
								</svg>
							</button>
						</div>
					</div>

					<div class="form-group">
						<label>Feedback</label>
						<input v-model="field.feedback" type="text" class="form-control">
					</div>
				</div>
			</div>
		</div>
		<div class="col-auto">
			<OrderMenu @remove="$emit('remove')" @up="$emit('up')" @down="$emit('down')"></OrderMenu>
		</div>
	</div>
</template>

<script>
	import OrderMenu from '@/components/OrderMenu';
	import FieldMixin from '@/mixins/Field';

    export default {
        name: 'FieldText',
		mixins: [FieldMixin],
		components: {OrderMenu},
		emits: ['remove', 'change'],
		data() {
            return {
                key: '',
				field: {
				    title: '',
                    feedback: '',
					keys: [],
				}
			}
		},
		methods: {
            addKey() {
                if(this.key !== '') {
                    this.field.keys.push(this.key);
                    this.key = '';
                }
			},
			addKeyKeyboard() {
                if (event.code === 'Enter') {
					this.addKey();
                }
			},
            removeKey(index) {
                this.field.keys.splice(index, 1);
			}
		}
    }
</script>