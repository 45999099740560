<template>
	<Nav></Nav>

	<div class="container-fluid px-5 my-5">
		<div class="row justify-content-center">
			<div class="col-5">
				<h1 class="mb-5">Logowanie</h1>

				<form @submit.prevent="loginAction">
					<div class="mb-4">
						<label for="inputEmail" class="form-label">Email</label>
						<input type="email" class="form-control" id="inputEmail" v-model="login">
					</div>
					<div class="mb-4">
						<label for="inputPassword" class="form-label">Hasło</label>
						<input type="password" class="form-control" id="inputPassword" v-model="password">
					</div>
					<button type="submit" class="btn btn-primary">Zaloguj</button>
				</form>

			</div>
		</div>
	</div>
</template>

<script>
    import Nav from '../components/Nav';

    export default {
        name: 'Login',
        components: {Nav},
        data() {
            return {
				login: '',
				password: ''
            }
        },
		methods: {
            loginAction() {
                let redirect = false;

                if( this.$route.query.redirect ) {
                    redirect = this.$route.query.redirect;
				}
                
                this.$store.dispatch('login', {login: this.login, password: this.password, redirect})
			}
		}
    }
</script>