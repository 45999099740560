import lang from '@/lang/lang';

export default {
    data() {
        return {
            validateErrors: [],
            allFields: [],
            formLanguage: 'pl',
        }
    },
    computed: {
        lang() {
            return lang(this.formLanguage);
        },
        errors() {
            return this.validateErrors;
        }
    },
    methods: {
        validate(target, name, rules) {
            const elem = name;
            const field = target;
            rules = rules.split('|');
            const value = field.value;

            this.removeErrors(elem);

            for(let i = 0; i < rules.length; i++) {
                let rule = rules[i];

                // required
                if (rule === 'required') {
                    if ( (field.type === 'checkbox' || field.type === 'radio') && document.querySelector('[name="' + name + '"]:checked') === null ) {
                        this.validateErrors.push({
                            'field': elem,
                            'message': this.lang.validation.required
                        });
                    }
                    if ( field.type !== 'checkbox' && !value ) {
                        this.validateErrors.push({
                            'field': elem,
                            'message': this.lang.validation.required
                        });
                    }
                }

                // email
                if (rule === 'email' && !this.validateEmail(value)) {
                    this.validateErrors.push({
                        'field': elem,
                        'message': this.lang.validation.email
                    });
                }

                // phone
                if (rule === 'phone' && !this.validatePhone(value)) {
                    this.validateErrors.push({
                        'field': elem,
                        'message': this.lang.validation.phone
                    });
                }

                // min
                if (rule.match('min') && value.length < rule.split(':')[1] ) {
                    this.validateErrors.push({
                        'field': elem,
                        'message': this.lang.validation.min + rule.split(':')[1]
                    });
                }

                // max
                if (rule.match('max') && value.length > rule.split(':')[1] ) {
                    this.validateErrors.push({
                        'field': elem,
                        'message': this.lang.validation.max + rule.split(':')[1]
                    });
                }

            }
        },

        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        validatePhone(phoneNumber) {
            const re = /^[0-9+ ]{9,15}$/;
            return re.test(String(phoneNumber).toLowerCase());
        },

        removeErrors(fieldName) {
            const errors = this.validateErrors;
            for (let i = 0; i < errors.length; i++) {
                if (errors[i]['field'] === fieldName) {
                    errors.splice(i, 1);
                    this.removeErrors(fieldName);
                }
            }
        },

        // get error by field name
        error(fieldName) {
            const errors = this.validateErrors;
            for (let i = 0; i < errors.length; i++) {
                if (errors[i]['field'] === fieldName) {
                    return errors[i]['message'];
                }
            }
        },

        validateTest(groupName) {
            this.validateErrors = [];

            if(groupName) return this.validateTestGroup(groupName);

            for (let i = 0; i < this.allFields.length; i++) {
                let field = this.allFields[i];
                this.validate(field.target, field.name, field.rules);
            }
            return this.validateErrors.length === 0;
        },

        validateTestGroup(groupName) {
            for (let i = 0; i < this.allFields.length; i++) {
                let field = this.allFields[i];

                if( field.group === groupName ) {
                    this.validate(field.target, field.name, field.rules);
                }
            }
            return this.validateErrors.length === 0;
        }
    },

    directives: {
        validate: {
            mounted(el, binding, vnode) {
                const $this = binding.instance;

                let data = {
                    target: el,
                    name: vnode.props.name,
                    rules: binding.value,
                };

                const group = vnode.props['v-validate-group'];

                data.group = group ? group : 'validate';

                $this.allFields.push(data);

                const action = function(event) {
                    if(event.type === 'keyup' && event.code === 'Tab') return;
                    $this.validate(el, vnode.props.name, binding.value);
                };
                // el.addEventListener('keyup', action);
                el.addEventListener('change', action);
            }
        }
    }
};