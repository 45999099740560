<template>
	<div class="col-12 mb-4 h4">Podsumowanie</div>

	<div class="mb-4">
		<label for="summary-type" class="col-12 mb-3">Typ podsumowania</label>
		<div class="col-12">
			<div class="mb-1 custom-control custom-radio">
				<input type="radio" class="custom-control-input" id="summary-type-standard" value="answers" v-model="summary.type">
				<label class="custom-control-label" for="summary-type-standard">Wskazanie błędnych odpowiedzi</label>
			</div>
			<div class="mb-1 custom-control custom-radio">
				<input type="radio" class="custom-control-input" id="summary-type-range" value="range" v-model="summary.type">
				<label class="custom-control-label" for="summary-type-range">Typ odpowiedzi</label>
			</div>
		</div>
	</div>

	<div class="col-12 mb-3" v-if="summary.type === 'range'">

		<div class="row mb-5" v-for="(item, key) in summary.summary">
			<div class="col-12 form-group mb-4">
				<label for="" class="h4">Tytuł</label>
				<input type="text" class="form-control" v-model="item.title">
			</div>
			<div class="col-12 form-group mb-4">
				<label for="" class="h4">Treść</label>
				<!--<textarea class="form-control" cols="30" rows="6" v-model="item.content"></textarea>-->
				<Wysiwyg :value="item.content" @change="item.content = $event"/>
			</div>
			<div class="col-12">
				<button class="btn btn-outline-secondary" type="button" @click="remove(key)">Usuń</button>
			</div>
		</div>

		<div class="col-12 text-right">
			<button @click="addSummaryItem" class="btn btn-outline-secondary" type="button">Dodaj</button>
		</div>

	</div>

	<!--<small><pre>{{ summary }}</pre></small>-->
</template>

<script>
    import Helpers from '@/mixins/Helpers';
    import Wysiwyg from '@/components/Wysiwyg';

    export default {
        name: 'Summary',
		props: ['summary'],
        mixins: [Helpers],
		components: {Wysiwyg},
		data() {
            return {
			}
		},
		methods: {
            addSummaryItem() {
                this.summary.summary.push(
                    {
						id: this.makeToken(),
                        title: '',
                        content: '',
						value: 0,
					}
				)
            },
            remove(key) {
                if(this.summary.summary.length === 1) return;
                this.summary.summary.splice(key, 1);
                this.calc();
            }
		}
    }
</script>