<template>
	<div class="row mb-3">
		<div class="col-11">
			<h3>Skala Likerta</h3>
			<!--<p class="small">Lorem ipsum dolor sit.</p>-->
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">

					<div class="form-group">
						<label>Etykieta</label>
						<input v-model="field.title" type="text" class="form-control" required>
					</div>

					<div class="select-multiple" v-if="summaryRange">
						<div class="form-check" v-for="item in summaries">
							<input v-model="field.summary" class="form-check-input" type="checkbox" :value="item.id" :id="'check-' + field.id + '-' + item.id">
							<label class="form-check-label" :for="'check-' + field.id + '-' + item.id">
								{{ item.title }}
							</label>
						</div>
					</div>

				</div>
			</div>
		</div>
		<div class="col-auto">
			<OrderMenu @remove="$emit('remove')" @up="$emit('up')" @down="$emit('down')"></OrderMenu>
		</div>
	</div>
</template>

<script>
    import OrderMenu from '@/components/OrderMenu';
    import FieldMixin from '@/mixins/Field';
    import Helpers from '@/mixins/Helpers';
    import UploadFile from '@/components/UploadFile';
    import BtnDelete from '@/components/form-create/BtnDelete';

    export default {
        name: 'FieldLikert',
        mixins: [FieldMixin, Helpers],
        components: {OrderMenu, UploadFile, BtnDelete},
		props: ['form'],
        emits: ['remove', 'change'],
		data() {
            return {
				field: {
				    title: '',
					summary: [],
                    id: this.makeToken()
				}
			}
		},
		computed: {
          	summary() {
          	    return this.form.content.summary;
			},
			summaryRange() {
                return this.form.content.summary.type === 'range';
			},
            summaries() {
          	    return this.form.content.summary.summary;
			}
		},
		methods: {
		}
    }
</script>