<template>
	<div class="row my-3">
		<div class="col-4">
			<h3>Załącznik</h3>
			<p>Załącznik, pole pozwala na załączenie plików</p>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Etykieta</label>
						<input v-model="field.title" type="text" class="form-control" required>
					</div>

					<div class="custom-control custom-checkbox mb-3">
						<input type="checkbox" v-model="field.anyExtension" class="custom-control-input" :id="token">
						<label class="custom-control-label" :for="token">Zezwalaj na dowolne rozszerzenie pliku</label>
					</div>

					<template v-if="!field.anyExtension">
						<p class="mb-2">Dozwolone rozszerzenia:</p>
						<div class="d-flex flex-wrap">
							<div v-for="el in field.extensions" class="custom-control custom-checkbox mr-5">
								<input type="checkbox" class="custom-control-input" v-model="el.checked" :id="token + el.title">
								<label class="custom-control-label" :for="token + el.title">{{ el.title }}</label>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div class="col-auto">
			<OrderMenu @remove="$emit('remove')" @up="$emit('up')" @down="$emit('down')"></OrderMenu>
		</div>
	</div>
</template>

<script>
	import OrderMenu from '@/components/OrderMenu';
    import FieldMixin from '@/mixins/Field';
    import Helpers from '@/mixins/Helpers';

    export default {
        name: 'FieldFile',
        mixins: [FieldMixin, Helpers],
		components: {OrderMenu},
        emits: ['remove', 'change'],
		data() {
            return {
                token: undefined,
				field: {
				    title: '',
					anyExtension: true,
					extensions: [
						{title: 'doc', checked: false},
						{title: 'pdf', checked: false},
						{title: 'jpg', checked: false},
						{title: 'jpeg', checked: false},
						{title: 'png', checked: false},
						{title: 'gif', checked: false},
					],
				}
			}
		},
        created() {
            this.token = this.makeToken();
		}
    }
</script>