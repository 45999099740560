import Vue from 'vue';
import Vuex from 'vuex';
import axios from "axios/index";
import router from '../router/index';
import {getCookie, setCookie} from "../helpers/cookies";
import {formModel} from "../models/formModel";

axios.defaults.headers = {
    "Content-Type": "application/x-www-form-urlencoded"
};

function setToken() {
    if (getCookie('auth') && getCookie('auth') !== '') {
        axios.defaults.headers.Authorization = 'Bearer ' + getCookie('auth');
    }
}

setToken();

function apiUrl(action) {
    setToken();
    return process.env.VUE_APP_BACKEND_URL + 'api/' + action;
}

const store = new Vuex.Store({
    state: {
        user: false,
        backendUrl: process.env.VUE_APP_BACKEND_URL,
        message: {
            show: false,
            message: '',
            type: ''
        },
        forms: [],
        form: formModel
    },
    mutations: {
        message(state, payload) {
            state.message = payload;
        },
        index(state, payload) {
            state.forms = payload;
        },
        get(state, payload) {

            function MergeRecursive(obj1, obj2) {
                for (var p in obj2) {
                    try {
                        // Property in destination object set; update its value.
                        if ( obj2[p].constructor==Object ) {
                            obj1[p] = MergeRecursive(obj1[p], obj2[p]);
                        } else {
                            if( !obj1[p] ) obj1[p] = obj2[p];
                        }
                    } catch(e) {
                        if( !obj1[p] ) obj1[p] = obj2[p];
                    }
                }
                return obj1;
            }
           state.form = MergeRecursive(payload, state.form)
           
            // state.form = payload;
        },
        cleanForms(state, payload) {
            state.form = formModel;
            state.forms = [];
        }
    },
    actions: {
        message(context, payload) {
            context.commit('message', {
                show: true,
                message: payload.message,
                type: payload.type
            });

            setTimeout(() => {
                context.commit('message', {
                    show: false,
                    message: '',
                    type: ''
                });
            }, 2000)
        },

        index(context, payload) {
            context.commit('cleanForms');
            return axios.get(apiUrl('forms'))
                .then(function (response) {
                    context.commit('index', response.data);
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                    // router.push({name: '404'});
                });
        },

        get(context, payload) {
            context.commit('cleanForms');
            return axios.get(apiUrl('forms/' + payload.token))
                .then(function (response) {
                    context.commit('get', response.data);
                })
                .catch(function (error) {
                    console.log(error);
                    // router.push({name: '404'});
                });
        },

        save(context, payload) {
            return axios.post(apiUrl('forms/store'), payload)
                .then(function (response) {
                    if (response.status === 200) {
                        context.dispatch('message', {message: 'Formularz zapisany poprawnie', type: 'success'});
                        router.push({name: 'AdminForms'});
                    } else {
                        console.log(response);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        duplicate(context, payload) {
            return axios.post(apiUrl('forms/' + payload.token + '/duplicate'))
                .then(function (response) {
                    if (response.status === 200) {
                        context.dispatch('message', {message: 'Formularz zduplikowany poprawnie', type: 'success'});
                        router.push({name: 'AdminForms'});
                    } else {
                        console.log(response);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        update(context, payload) {
            return axios.put(apiUrl('forms/' + payload.token), payload)
                .then(function (response) {
                    if (response.status === 200) {
                        context.dispatch('message', {message: 'Formularz zapisany poprawnie', type: 'success'});
                        router.push({name: 'AdminForms'});
                    } else {
                        console.log(response);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        destroy(context, payload) {
            return axios.delete(apiUrl('forms/' + payload.token))
                .then(function (response) {
                    if (response.status === 200) {
                        context.dispatch('message', {message: 'Formularz usunięty poprawnie', type: 'success'});
                        router.push({name: 'AdminForms'});
                    } else {
                        console.log(response);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        sendLeadForm(context, payload) {
            return axios.post(apiUrl('leads'), payload)
                .then(function (response) {
                    return response;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        login(context, payload) {
            return axios.post(apiUrl('login'), payload)
                .then(function (response) {
                    if (response.status === 200) {
                        setCookie('auth', response.data.token, 10);

                        if (payload.redirect) {
                            router.push(payload.redirect);
                        } else {
                            router.push({name: 'AdminForms'});
                        }

                    } else {
                        alert('błędny login lub hasło')
                    }
                })
                .catch(function (error) {
                    alert('błędny login lub hasło')
                    console.log(error);
                });
        }
    }
});

export default store;