import { createWebHistory, createWebHashHistory, createRouter } from "vue-router";

import {getCookie} from '../helpers/cookies';

import Home from "../pages/Home.vue";
import Form from "../pages/Form.vue";
import Forms from "../pages/Admin/Forms.vue";
import FormCreate from "../pages/Admin/FormCreate.vue";
import Login from '../pages/Login';

const routes = [
    {
        path: "/admin",
        redirect: '/admin/forms'
    },
    {
        path: "/admin/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/admin/forms",
        name: "AdminForms",
        component: Forms,
        meta: { requiresAuth: true }
    },
    {
        path: "/admin/forms/create",
        name: "FormCreate",
        component: FormCreate,
    },
    {
        path: "/admin/forms/:token",
        name: "FormEdit",
        component: FormCreate,
    },
    {
        path: "/:token",
        name: "Form",
        component: Form,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

function isLogged() {
    const cookie = getCookie('auth');
    return cookie && cookie !== '';
}

router.beforeEach((to, from) => {
    if (to.meta.requiresAuth && !isLogged()) {
        return {
            path: '/admin/login',
            query: { redirect: to.fullPath },
        }
    }
});

export default router;