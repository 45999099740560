<template>
	<div class="row mb-3">
		<div class="col-11">
			<h3>Checkbox</h3>
			<p class="small">Chceckbox, należy podać treść wyświetlającą się obok checkboxa</p>
		</div>
		<div class="col">
			<div class="card">
				<div class="card-body">

					<div class="form-group">
						<label>Etykieta</label>
						<input v-model="field.title" type="text" class="form-control" required>
					</div>

					<div class="custom-control custom-checkbox mb-3">
						<input type="checkbox" v-model="field.required" class="custom-control-input" :id="token">
						<label class="custom-control-label" :for="token">Pole obowiązkowe?</label>
					</div>

				</div>
			</div>
		</div>
		<div class="col-auto">
			<OrderMenu @remove="$emit('remove')" @up="$emit('up')" @down="$emit('down')"></OrderMenu>
		</div>
	</div>
</template>

<script>
    import OrderMenu from '@/components/OrderMenu';
    import FieldMixin from '@/mixins/Field';
    import Helpers from '@/mixins/Helpers';

    export default {
        name: 'FieldCheckbox',
        mixins: [FieldMixin, Helpers],
        components: {OrderMenu},
        emits: ['remove', 'change'],
		data() {
            return {
                token: null,
				field: {
				    title: '',
					required: false,
				}
			}
		},
		created() {
            this.token = this.makeToken();
		}
    }
</script>