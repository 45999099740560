<template>
	<div class="nav-branding-wrapper">
	<div class="container-fluid py-2">
		<nav class="navbar">
			<a target="_blank" class="navbar-brand" href="https://adwisegroup.pl/">
				<img style="width: 250px;" src="../assets/logo.png" alt="">
			</a>

			<router-link replace v-if="form && form.content.settings.otherLanguage.token" :to="{name: 'Form', params: {token: form.content.settings.otherLanguage.token}}">
				<svg v-if="form.content.settings.otherLanguage.language === 'pl'" class="mr-2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width: 20px;" xml:space="preserve">
					<rect y="85.337" style="fill:#F0F0F0;" width="512" height="341.326"/>
					<rect y="85.337" style="fill:#F0F0F0;" width="512" height="170.663"/>
					<rect y="256" style="fill:#D80027;" width="512" height="170.663"/>
				</svg>
				<svg v-if="form.content.settings.otherLanguage.language === 'ru'" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
					 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512; width: 20px;" xml:space="preserve">
					<polygon style="fill:#F0F0F0;" points="0,85.33 0,199.107 0,312.885 0,426.662 512,426.662 512,312.885 512,199.107 512,85.33 "/>
					<rect y="85.333" style="fill:#0052B4;" width="512" height="341.337"/>
					<rect y="85.333" style="fill:#F0F0F0;" width="512" height="113.775"/>
					<rect y="312.884" style="fill:#D80027;" width="512" height="113.775"/>
				</svg>
			</router-link>
		</nav>
	</div>
	</div>
</template>

<script>
    export default {
        name: 'Nav',
		props: ['form']
    }
</script>