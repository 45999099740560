<template>
	<div class="d-flex flex-column">
		<button type="button" class="btn btn-danger mb-2" @click="$emit('remove')">Usuń</button>
		<div class="btn-group-vertical" role="group">
			<button type="button" class="btn btn-secondary" @click="$emit('up')">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
					<path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
				</svg>
			</button>
			<button type="button" class="btn btn-secondary" @click="$emit('down')">
				<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
					<path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
				</svg>
			</button>
		</div>
	</div>
</template>

<script>
    export default {
        name: 'OrderMenu',
    }
</script>