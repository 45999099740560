const ru = {
    start: 'Начни',
    startTest: 'Начни тест',
    back: 'Назад',
    next: 'Далее',
    check: 'Проверь',
    step: 'шаг',
    of: 'из',
    question: 'Вопрос',
    correctAnswer: 'Правильный ответ!',
    correctAnswer2: 'Правильный ответ:',
    badAnswer: 'Неправильный ответ',
    yourAnswer: 'ваш ответ:',
    contactData: 'Контактная <br>информация',
    lickertDown: 'Точно НЕТ',
    lickertUp: 'Точно ДА',
    personalDataAdmin: `Администраторами персональных данных являются: Университет WSB во Вроцлаве, ул. Fabryczna 29-31, 
    Университет WSB в Познани, ул. Powstańców Wlkp 5, Университет WSB в Гданьске, al. Grunwaldzka 238A, Университет WSB 
    в Торуни, ул. Młodzieowa 31a, Университет WSB в Варшаве, ул. Abiszyńska 25, далее WSB`,
    thx: {
        correct: 'Поздравляю, все ответы верны',
        bad: 'К сожалению, не все ваши ответы были правильными.',
        score: 'ваш счет:',
        summary: 'Ниже вы найдете резюме:'
    },
    validation: {
        required: 'Это поле обязательно для заполнения',
        email: 'Пожалуйста, введите действительный адрес электронной почты',
        phone: 'Пожалуйста, введите действующий телефонный номер',
        min: 'Минимальное количество символов: ',
        max: 'Максимальное количество символов: ',
    },
    contact: {
        contactData: 'Контактная информация',
        firstName: 'Имя',
        lastName: 'Имя',
        email: 'Электронная почта',
        phone: 'телефон',
        location: 'Выберите ближайший университет WSB',
    }
};

export default ru;