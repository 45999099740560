<template>
	<Nav/>
	<div class="container-fluid px-5 my-5">
		<div class="row d-flex justify-content-center">
			<div class="col-6">

				<div class="table-wrapper mb-3">
					<table class="table m-0">
						<thead>
						<tr>
							<th class="col small font-weight-bold">Tytuł</th>
							<th class="col"></th>
						</tr>
						</thead>
						<tbody>
						<tr v-for="form in forms">
							<td>
								<router-link :to="{name: 'FormEdit', params: {token: form.slug}}">{{ form.title }}</router-link>
							</td>
							<td>
								<div class="d-flex">
									<button @click="duplicateForm(form.slug)" class="btn btn-primary mr-2">Duplikuj</button>
									<router-link :to="{name: 'FormEdit', params: {token: form.slug}}" class="btn btn-primary mr-2">Edycja</router-link>
									<router-link :to="{name: 'Form', params: {token: form.slug}}" class="btn btn-info">Podgląd</router-link>
								</div>
							</td>
						</tr>
						</tbody>
					</table>
				</div>

				<div class="w-100 text-right">
					<router-link :to="{name: 'FormCreate'}" class="btn btn-primary">Dodaj nowy formularz</router-link>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
    import Nav from '@/components/Nav';

    export default {
        name: 'Admin',
        components: {Nav},
        data() {
            return {
            }
        },
        computed: {
            forms() {
                return this.$store.state.forms;
            }
        },
        created() {
            this.$store.dispatch('index');
            document.body.classList.add('admin');
        },
		methods: {
            duplicateForm(token) {
                const $this = this;
                this.$store.dispatch('duplicate', {token})
					.then(function(){
                        $this.$store.dispatch('index');
					})
			}
		}
    }
</script>