import {agreementsModel} from "./agreementsModel";
import makeToken from '../mixins/Helpers';

const formModel = {
    title: '',
    slug: '',
    content: {
        graphics: '',
        description: '',
        agreements: agreementsModel,
        elements: [],
        information: {
            excerpt: 'Więcej o przetwarzaniu danych osobowych',
            more: `Dane osobowe będą przetwarzane w celach przekazywania informacji prasowych oraz w związku z działalnością PR Administratorów Danych i ich współpracy z dziennikarzami.
Dane osobowe przetwarzane będą do czasu wycofania zgody na przetwarzanie bądź do czasu zakończenia współpracy z osobą której dane dotyczą. Administrator danych może jednak zachować ograniczoną część danych w celu wykazania zgodności z przepisami. Odbiorcami danych będą podmioty współpracujące w Administratorem Danych w celu zapewnienia prawidłowej realizacji zadań.
W związku z korzystaniem przez Administratora z narzędzi Microsoft Office 365, dane osobowe mogą być przekazywane poza terytorium EOG, w tym do USA.
Osobie przysługuje prawo dostępu do treści swoich danych i ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania.
Osobie przysługuje prawo wniesienia skargi do PUODO, gdy uzna, że przetwarzanie jej danych osobowych, narusza przepisy Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).
W przypadku pytań dotyczących przetwarzania Państwa danych osobowych prosimy o kontakt z naszym Inspektorem Ochrony Danych iod@wsb.pl.`,
        },
        settings: {
            questionsPerPage: 1,
            quizType: 'classic', // classic | likert
            hideForm: false,
            textColor: '#22284F',
            textBackground: {
                color: "transparent",
                opacity: "100"
            },
            language: 'pl',
            otherLanguage: {
                token: null,
                language: null,
            },
        },
        summary: {
            type: 'answers',
            summary: [
                {
                    id: makeToken.methods.makeToken(),
                    title: '',
                    content: '',
                    value: 0,
                    likert: 0,
                }
            ]
        },
        interestProduct: 'inz'
    },
};

export {formModel};