<template>
	<div class="upload-area">
		<input class="d-none" type="file" multiple id="assetsFieldHandle"
				@change="onChange" ref="file" :accept="extensions.join(',')" />

		<div class="drop-area" @dragover="dragover" @dragleave="dragleave" @drop="drop">
			<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
				<path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
				<path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
			</svg>
			<div style="pointer-events: none;">
				<div class="drop-info"><strong><label for="assetsFieldHandle" style="pointer-events: all">Wybierz plik</label></strong> lub przeciągnij tutaj</div>
				<div class="if-drop"><strong>Upuść plik tutaj</strong></div>
				<small v-if="!element.value.anyExtension">Dozwolone formaty pliku: {{ extensions.join(', ') }}</small>
			</div>
		</div>

		<div class="uploaded-files" v-if="files.length">
			<div v-for="(file, index) in files" class="single-file">
				<div class="file-name">{{ file.name }}</div>
				<div class="file-size">{{ file.size }} MB</div>
				<div class="file-delete">
					<svg @click="remove(index)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash-fill" viewBox="0 0 16 16">
						<path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
					</svg>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios';

    export default {
        name: 'UploadFile',
		props: ['element', 'single', 'imgSize'],
		emits: ['change'],
		data() {
            return {
                files: [],
				imageSize: {
                    w: 0,
					h: 0
				}
			}
		},
		watch: {
          	files() {
                this.$emit('change', this.files);
			}
		},
		computed: {
            extensions() {
				if( this.element.value.anyExtension ) {
                    return [];
				}else{
                    const extensions = this.element.value.extensions;
                    let checkedExtensions = [];

                    for(let i = 0; i < extensions.length; i++) {
                        if( extensions[i].checked ){
                            checkedExtensions.push('.' + extensions[i].title)
                        }
                    }

                    return checkedExtensions;
				}
			}
		},
		methods: {
            onChange(event) {
                const files = this.$refs.file.files;
                for(let i = 0; i < files.length; i++ ) {
                    this.upload(files[i]);
                }
            },
            remove(i) {
                this.files.splice(i, 1);
            },
            dragover(event) {
                event.preventDefault();
                event.currentTarget.parentNode.classList.add('drop');
            },
            dragleave(event) {
                event.currentTarget.parentNode.classList.remove('drop');
            },
            drop(event) {
                event.preventDefault();
                const files = event.dataTransfer.files;
                for(let i = 0; i < files.length; i++ ) {

                    if( !this.element.value.anyExtension ) {
                        const fileExtension = files[i].name.split('.').pop();
                        if (this.extensions.indexOf('.' + fileExtension) === -1) {
                            alert('Niedozwolony format pliku');
                            return;
                        }
                    }

                    this.upload(files[i]);
                }
                event.currentTarget.parentNode.classList.remove('drop');
            },

            fileSize(file) {
                return Math.ceil((file.size / 1024000) * 100) / 100
            },

			calcImageSize(file) {
                this.imageSize = {w:0, h:0};
                let img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = () => {
                    this.imageSize = {
                        w: img.width,
						h: img.height
					};
					this.upload(file);
                }
			},

            upload(file) {

                if( this.imgSize ) {
                    if (this.imageSize.w === 0 && this.imageSize.h === 0) {
                        return this.calcImageSize(file);
                    }
                    if (this.imageSize.w !== this.imgSize[0] || this.imageSize.h !== this.imgSize[1]) {
                        alert('Grafika musi mieć rozmiar 920px x 260px');
                        return;
                    }
                }

				if (this.single && this.files.length > 0) {
					this.files = [];
                }
                
				const $this = this;
                let formData = new FormData();
                formData.append("file", file);

                return axios.post( process.env.VUE_APP_BACKEND_URL + 'api/files', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                    .then(function (response) {
                        $this.files.push({
                            name: file.name,
                            size: $this.fileSize(file),
                            token: response.data.token
                        });
                        $this.$emit('change', $this.files);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
			}
		}
    }
</script>