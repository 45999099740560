export default {
    props: ['value'],
    created() {
        if(this.value) {
            this.field = this.value;
        }
    },
    methods: {
        emitField() {
            this.$emit('change', this.field);
        }
    }
};